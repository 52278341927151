import { Card } from "react-bootstrap";

function BibTeXCard({ htmlContent }) {
  const cleanedHtmlContent = htmlContent.replace("\n<dl>\n<dt>1</dt>", "");

  return (
    <Card className="mb-3">
      <Card.Header
        as="h5"
        style={{
          backgroundColor: "#00507f",
          color: "white",
        }}
      >
        Reference
      </Card.Header>
      <Card.Body>
        <div dangerouslySetInnerHTML={{ __html: cleanedHtmlContent }} />
      </Card.Body>
    </Card>
  );
}

export default BibTeXCard;
