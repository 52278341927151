import { Modal } from "antd";
import React from "react";

const GuidelinesModal = ({ modalVisible, onModalClose }) => {
  // Method to handle the 'OK' action
  const handleModalOk = () => {
    onModalClose(); // Close the modal
  };

  // Method to handle the 'Close' action
  const handleModalClose = () => {
    onModalClose(); // Close the modal
  };

  return (
    <Modal
      title="File Upload Guidelines & Examples"
      open={modalVisible}
      onOk={handleModalOk}
      onCancel={handleModalClose}
      footer={null}
      width="auto" // Set the width to auto to adjust based on content
      style={{ maxWidth: "60%", maxHeight: "80%" }}
    >
      <div className="container">
        <h3>Input Files Format</h3>
        <p>
          <strong>Minimum input file format:</strong>
        </p>
        <ul>
          <li>
            Your CSV file must contain at least one column named{" "}
            <code>tagged_text</code>.
          </li>
          <li>
            The text data in this column should minimally include one tagged
            expression, using the tag <code>&lt;m&gt;</code>,{" "}
            <code>&lt;l&gt;</code>, <code>&lt;t&gt;</code>,{" "}
            <code>&lt;a&gt;</code>, or <code>&lt;u&gt;</code>, to specify the
            label of an expression in context:
          </li>
          <ul>
            <li>
              <code>&lt;m&gt;</code>: metaphorical
            </li>
            <li>
              <code>&lt;l&gt;</code>: literal
            </li>
            <li>
              <code>&lt;a&gt;</code>: semantically anomalous
            </li>
            <li>
              <code>&lt;u&gt;</code>: open tag often used to mark annotators'
              uncertainty in our format between <code>&lt;l&gt;</code> and{" "}
              <code>&lt;m&gt;</code> tags
            </li>
            <li>
              <code>&lt;t&gt;</code>: used to optionally mark words belonging to
              the target domain of a metaphor (or tenor)
            </li>
          </ul>
          <p></p>
          <p>
            Example (1): Pinpointing this anomaly is the key to{" "}
            <code>&lt;m&gt;unraveling&lt;/m&gt;</code> wealth and greater
            returns.
          </p>
          <p>
            Example (2): The National Highway Traffic Safety Administration,
            meanwhile, has proposed <code>&lt;l&gt;long-delayed&lt;/l&gt;</code>{" "}
            regulations requiring auto makers to include event data recorders –
            better known as “black boxes” – in all new cars and light trucks
            beginning in 2014.
          </p>
        </ul>

        <p>
          <a href="/minimal.csv" download="minimal-csv-example.csv">
            Minimal CSV Example
          </a>
        </p>

        <p>
          <strong>Input format with additional information:</strong>
        </p>
        <ul>
          <li>
            The column <code>tagged_text</code> accepts another special tag{" "}
            <code>&lt;t&gt;</code>, that indicates the primary tenor of the
            metaphor.
          </li>
          <p></p>
          <p>
            Example (3): Pinpointing this anomaly is the key to{" "}
            <code>&lt;m&gt;unraveling&lt;/m&gt;</code>{" "}
            <code>&lt;t&gt;wealth&lt;/t&gt;</code> and greater returns.
          </p>
          <li>
            Multiple <code>&lt;m&gt;</code>, <code>&lt;l&gt;</code>,{" "}
            <code>&lt;u&gt;</code>, <code>&lt;a&gt;</code>, and{" "}
            <code>&lt;t&gt;</code> tags are accepted in the same tagged
            sentence.
          </li>
          <li>
            Other columns can be added to input any additional information, such
            as the part of speech of the tagged expression, source and target
            domains, metaphoricity or emotion ratings, additional context, etc.
            The naming convention of columns are provide in a downloadable file.
          </li>
        </ul>
        <p>
          <a
            href="/sample.csv"
            download="csv-with-additional-columns-example.csv"
          >
            CSV with Additional Columns Example
          </a>
        </p>
        <p>
          <a href="/fields.csv" download="expected-column-names.csv">
            Expected Columns and Naming Conventions
          </a>
        </p>

        <h3>Example Files</h3>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <img
              src="minimal-csv.png"
              alt="Screenshot 1"
              className="img-fluid"
            />
            <p>Minimum input file format - example csv</p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 text-center">
            <img
              src="sample-additional-fields.png"
              alt="Screenshot 2"
              className="img-fluid"
            />
            <p>Input format with additional information - example csv</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GuidelinesModal;
