export const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/.*)?$/;

export const partsOfSpeechOptions = [
  "Any",
  "Noun",
  "Verb",
  "Adjective",
  "Adverb",
  "Preposition",
  "Various",
];

export const license_list = [
  "Apache license 2.0",
  "MIT",
  "Academic Free License v3.0",
  "Artistic license 2.0",
  "Boost Software License 1.0",
  "BSD license family",
  "BSD 2-clause “Simplified” license",
  "BSD 3-clause “New” or “Revised” license",
  "BSD 3-clause Clear license",
  "Computational Use of Data Agreement",
  "Creative Commons license family",
  "Creative Commons Zero v1.0 Universal",
  "Creative Commons Attribution 2.0",
  "Creative Commons Attribution 2.5",
  "Creative Commons Attribution 3.0",
  "Creative Commons Attribution 4.0",
  "Creative Commons Attribution Share Alike 3.0",
  "Creative Commons Attribution Share Alike 4.0",
  "Creative Commons Attribution Non Commercial 2.0",
  "Creative Commons Attribution Non Commercial 3.0",
  "Creative Commons Attribution Non Commercial 4.0",
  "Creative Commons Attribution No Derivatives 4.0",
  "Creative Commons Attribution Non Commercial No Derivatives 3.0",
  "Creative Commons Attribution Non Commercial No Derivatives 4.0",
  "Creative Commons Attribution Non Commercial Share Alike 2.0",
  "Creative Commons Attribution Non Commercial Share Alike 3.0",
  "Creative Commons Attribution Non Commercial Share Alike 4.0",
  "Community Data License Agreement – Sharing, Version 1.0",
  "Community Data License Agreement – Permissive, Version 1.0",
  "Community Data License Agreement – Permissive, Version 2.0",
  "Do What The F*ck You Want To Public License",
  "Educational Community License v2.0",
  "Eclipse Public License 1.0",
  "Eclipse Public License 2.0",
  "Etalab Open License 2.0",
  "European Union Public License 1.1",
  "GNU Affero General Public License v3.0",
  "GNU Free Documentation License family",
  "GNU General Public License family",
  "GNU General Public License v2.0",
  "GNU General Public License v3.0",
  "GNU Lesser General Public License family",
  "GNU Lesser General Public License v2.1",
  "GNU Lesser General Public License v3.0",
  "ISC",
  "LaTeX Project Public License v1.3c",
  "Microsoft Public License",
  "Mozilla Public License 2.0",
  "Open Data Commons License Attribution family",
  "Open Database License family",
  "Open Software License 3.0",
  "PostgreSQL License",
  "SIL Open Font License 1.1",
  "University of Illinois/NCSA Open Source License",
  "The Unlicense",
  "zLib License",
  "Open Data Commons Public Domain Dedication and License",
  "Lesser General Public License For Linguistic Resources",
  "Other",
];
