import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatasetDetails,
  getUBMIDatasetsSearchable,
} from "../store/search.js";
import { downloadDatasetFile } from "../store/api.js";
import { Card, Badge, ListGroup } from "react-bootstrap";
import BarChart from "./visualizations/BarChart.js";
import { Form, Select, Spin, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import BibTeXCard from "./visualizations/BibTeXCard.js";

const labelReplacements = {
  m: "Metaphor",
  l: "Literal",
  t: "Target",
  a: "Anomalous",
  u: "Uncertain",
};

const getLabelData = (labels) => {
  return labels.label_set.map((label, index) => ({
    label: labelReplacements[label] || label,
    count: labels.labels_counts[index],
  }));
};

const isValuePresent = (value) => {
  return (
    value !== undefined &&
    value !== null &&
    value !== "" &&
    !(Array.isArray(value) && value.length === 0) &&
    !(typeof value === "object" && Object.keys(value).length === 0)
  );
};

const filterFields = (fields) => {
  const knownFields = [
    "categorical",
    "scalar",
    "license",
    "license_other",
    "bibtex",
    "additional_comments",
    "additional_comment",
  ];
  return Object.entries(fields).filter(
    ([key, value]) => !knownFields.includes(key) && isValuePresent(value)
  );
};

const renderValue = (key, value) => {
  let valueToRender = value;
  if (!isValuePresent(value)) return null;

  if (Array.isArray(value)) {
    valueToRender = value.join(", ");
  } else if (typeof value === "string") {
    if (value.includes(",")) {
      valueToRender = value
        .split(",")
        .map((item) => item.trim())
        .join(", ");
    } else {
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue)) {
          valueToRender = parsedValue.join(", ");
        } else if (typeof parsedValue === "object") {
          valueToRender = Object.entries(parsedValue)
            .map(([k, v]) => `${k}: ${v}`)
            .join(", ");
        }
      } catch (e) {
        // value remains as string
      }
    }
  } else if (typeof value === "object") {
    valueToRender = Object.entries(value)
      .map(([k, v]) => `${k}: ${v}`)
      .join(", ");
  }

  if (key === "bibtex") {
    return <BibTeXCard htmlContent={value} />;
  } else if (key === "size") {
    return valueToRender + " Rows";
  } else if (key === "date_added") {
    const date = new Date(value);
    const formattedDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  } else if (typeof value === "boolean") {
    return value ? (
      <Badge bg="success">Yes</Badge>
    ) : (
      <Badge bg="danger">No</Badge>
    );
  } else {
    return valueToRender;
  }
};

const renderAdditionalFields = (additionalFields) => {
  const filteredFields = filterFields(additionalFields);

  return filteredFields.map(([key, value], index) => {
    if (Array.isArray(value)) {
      value = value.join(", ");
    } else if (typeof value === "string") {
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue)) {
          value = parsedValue.join(", ");
        }
      } catch (e) {
        // value remains as string
      }
    }
    return (
      <div className="col-md-6" key={index}>
        <p>
          <strong>{key}:</strong> {renderValue(key, value)}
        </p>
      </div>
    );
  });
};

const renderAuthors = (authors) => {
  if (!authors) return "N/A";
  if (Array.isArray(authors)) return authors.join(", ");
  if (typeof authors === "string") {
    try {
      const parsedAuthors = JSON.parse(authors);
      if (Array.isArray(parsedAuthors)) return parsedAuthors.join(", ");
    } catch (e) {
      return authors;
    }
  }
  return authors;
};

const getAdditionalComments = (datasetDetails) => {
  const comments = [];
  if (datasetDetails.additional_comments) {
    comments.push(datasetDetails.additional_comments);
  }
  if (datasetDetails.additional_comment) {
    comments.push(datasetDetails.additional_comment);
  }
  if (datasetDetails.additional_fields) {
    if (datasetDetails.additional_fields.additional_comments) {
      comments.push(datasetDetails.additional_fields.additional_comments);
    }
    if (datasetDetails.additional_fields.additional_comment) {
      comments.push(datasetDetails.additional_fields.additional_comment);
    }
  }
  return comments.join("//***//");
};

function Dataset() {
  const { Option } = Select;
  let { datasetName } = useParams();
  const [selectedDataset, setSelectedDataset] = useState("");
  const dispatch = useDispatch();
  const { loading, datasetDetails, datasetsSearchableList } = useSelector(
    (state) => state.search
  );

  // Fetch dataset list if no datasetName param is passed
  useEffect(() => {
    dispatch(getUBMIDatasetsSearchable());
  }, [dispatch, datasetName]);

  // Set the selected dataset when datasetsSearchableList is available or when datasetName changes
  useEffect(() => {
    if (datasetsSearchableList.length > 0 && !datasetName) {
      setSelectedDataset(Object.keys(datasetsSearchableList)[0]);
    } else if (datasetName) {
      setSelectedDataset(datasetName);
    }
  }, [datasetsSearchableList, datasetName]);

  // Fetch dataset details when selectedDataset changes
  useEffect(() => {
    if (selectedDataset) {
      dispatch(getDatasetDetails({ dataset_name: selectedDataset }));
    }
  }, [dispatch, selectedDataset]);

  const handleDownload = async () => {
    try {
      if (selectedDataset) {
        const response = await downloadDatasetFile(selectedDataset);

        // Create a blob from the response data
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        // Trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          datasetDetails?.file_name || "dataset.csv"
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("File download failed:", error);
    }
  };

  return (
    <div className="p-4">
      <div className="mb-3">
        {" "}
        {/* Bootstrap class for margin-bottom */}
        <div className="row g-2">
          {" "}
          {/* Bootstrap row with gutters */}
          <h2
            style={{
              textAlign: "center",
              backgroundColor: "#001529",
              color: "white",
              padding: "10px",
            }}
          >
            Dataset Catalogue
          </h2>
          <div className="col">
            <Form.Item
              label={
                <span style={{ fontWeight: "bold", color: "#001529" }}>
                  Select Dataset:
                </span>
              }
              style={{ maxWidth: "500px", margin: "0 auto" }}
            >
              <Select
                showSearch
                mode="single"
                style={{ width: "100%" }}
                placeholder="Search by Dataset"
                value={selectedDataset}
                onChange={(value) => {
                  setSelectedDataset(value);
                }}
              >
                {Object.entries(datasetsSearchableList)?.map(([key, value]) => (
                  <Option key={key} value={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row g-2">
          <div
            className="col p-4"
            style={{
              display: "flex",
              justifyContent: "center", // Centers horizontally
              alignItems: "center", // Centers vertically
              height: "100%", // Ensures the parent div takes enough height for vertical centering
            }}
          >
            <Button
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleDownload}
              disabled={datasetDetails?.file_name == null}
            >
              Download Dataset
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin size="large" />
        </div>
      ) : (
        datasetDetails && (
          <>
            <div
              className="col"
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                marginBottom: "20px",
              }}
            >
              <div className="row mb-3">
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  Dataset Name:{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {datasetDetails?.dataset_name}
                  </span>
                </div>
              </div>
              <div className="row mb-3">
                {isValuePresent(datasetDetails?.dataset_main_author) && (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "1.5rem",
                    }}
                  >
                    Main Author:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {renderValue(
                        "dataset_main_author",
                        datasetDetails.dataset_main_author
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Related Research Papers
                    </Card.Title>
                    {datasetDetails?.papers?.length > 0 ? (
                      <ListGroup>
                        {datasetDetails?.papers?.map((paper, index) => (
                          <ListGroup.Item key={index}>
                            <h5>{paper.title}</h5>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="col-md-6">
                                  <p>{renderAuthors(paper.authors)}</p>
                                </div>
                                <div className="col-md-6">
                                  <p>{paper.year}</p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <a
                                      href={paper.links}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {paper.links}
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                {renderValue("bibtex", paper.bibtex)}
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : datasetDetails?.paper && datasetDetails?.authors ? (
                      <ListGroup>
                        <ListGroup.Item key={1233}>
                          <h5>{datasetDetails?.paper?.title}</h5>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="col-md-6">
                                <p>{renderAuthors(datasetDetails?.authors)}</p>
                              </div>
                              <div className="col-md-6">
                                <p>{datasetDetails?.year}</p>
                              </div>
                              <div className="col-md-6">
                                <p></p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              {renderValue(
                                "bibtex",
                                datasetDetails?.additional_fields?.bibtex
                              )}
                            </div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    ) : (
                      <p>No related research papers available.</p>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Metadata
                    </Card.Title>
                    <div className="row">
                      {isValuePresent(datasetDetails?.dataset_id) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Dataset ID:</strong>{" "}
                            {renderValue(
                              "dataset_id",
                              datasetDetails.dataset_id
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.reviewed) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Reviewed:</strong>{" "}
                            {renderValue("reviewed", datasetDetails.reviewed)}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.date_added) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Date Added:</strong>{" "}
                            {renderValue(
                              "date_added",
                              datasetDetails.date_added
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.corpus?.genre) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Genre:</strong>{" "}
                            {renderValue(
                              "corpus.genre",
                              datasetDetails.corpus.genre
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.corpus?.source) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Source:</strong>{" "}
                            {renderValue(
                              "corpus.source",
                              datasetDetails.corpus.source
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {isValuePresent(datasetDetails?.language) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Language:</strong>{" "}
                            {renderValue("language", datasetDetails.language)}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.discipline) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Discipline:</strong>{" "}
                            {renderValue(
                              "discipline",
                              datasetDetails.discipline
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.format) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Format:</strong>{" "}
                            {renderValue("format", datasetDetails.format)}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {isValuePresent(
                        getAdditionalComments(datasetDetails)
                      ) && (
                        <div className="col-md-12">
                          <p>
                            <strong>Additional Comments:</strong>{" "}
                            {renderValue(
                              "additional_comments",
                              getAdditionalComments(datasetDetails)
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Labels Information
                    </Card.Title>
                    <div className="row">
                      {isValuePresent(datasetDetails?.size) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Dataset Size:</strong>{" "}
                            {renderValue("size", datasetDetails.size)}
                          </p>
                        </div>
                      )}
                    </div>
                    {isValuePresent(datasetDetails?.labels) ? (
                      <div className="row">
                        <div className="col-md-4">
                          <ListGroup>
                            {getLabelData(datasetDetails.labels).map(
                              (item, index) => (
                                <ListGroup.Item key={index}>
                                  <p>
                                    <strong>{item.label}:</strong> {item.count}
                                  </p>
                                </ListGroup.Item>
                              )
                            )}
                          </ListGroup>
                        </div>
                        <div className="col-md-8">
                          <BarChart
                            labels={datasetDetails.labels.label_set.map(
                              (label) => labelReplacements[label] || label
                            )}
                            data={datasetDetails.labels.labels_counts}
                            chartTitle="Label Distribution"
                          />
                        </div>
                      </div>
                    ) : (
                      <p>No label information available.</p>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Annotation Details
                    </Card.Title>
                    <h5>Annotation Type</h5>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.annotation_type?.tenor_vehicle
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Tenor Vehicle:</strong>{" "}
                            {renderValue(
                              "tenor_vehicle",
                              datasetDetails.annotation_type.tenor_vehicle
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.annotation_type?.tenor_pos
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Tenor POS:</strong>{" "}
                            {renderValue(
                              "tenor_pos",
                              datasetDetails.annotation_type.tenor_pos
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.annotation_type?.vehicle_pos
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Vehicle POS:</strong>{" "}
                            {renderValue(
                              "vehicle_pos",
                              datasetDetails.annotation_type.vehicle_pos
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.annotation_type?.vehicle
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Vehicle:</strong>{" "}
                            {renderValue(
                              "vehicle",
                              datasetDetails.annotation_type.vehicle
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.annotation_type?.rating_scales
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Rating Scales:</strong>{" "}
                            {renderValue(
                              "rating_scales",
                              JSON.stringify(
                                datasetDetails.annotation_type.rating_scales
                              )
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <h5>Annotation Procedure</h5>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.annotation_procedure?.IAA
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>IAA:</strong>{" "}
                            {renderValue(
                              "IAA",
                              datasetDetails.annotation_procedure.IAA
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.annotation_procedure?.other
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Other:</strong>{" "}
                            {renderValue(
                              "other",
                              datasetDetails.annotation_procedure.other
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.annotation_procedure?.n_annotator
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Number of Annotators:</strong>{" "}
                            {renderValue(
                              "n_annotator",
                              datasetDetails.annotation_procedure.n_annotator
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.annotation_procedure?.annotator_profile
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Annotator Profile:</strong>{" "}
                            {renderValue(
                              "annotator_profile",
                              datasetDetails.annotation_procedure
                                .annotator_profile
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Context
                    </Card.Title>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.context?.["1_sentence"]
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>1 Sentence:</strong>{" "}
                            {renderValue(
                              "1_sentence",
                              datasetDetails.context["1_sentence"]
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.context?.["3_sentences"]
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>3 Sentences:</strong>{" "}
                            {renderValue(
                              "3_sentences",
                              datasetDetails.context["3_sentences"]
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.context?.["5_sentences"]
                      ) && (
                        <div className="col-md-4">
                          <p>
                            <strong>5 Sentences:</strong>{" "}
                            {renderValue(
                              "5_sentences",
                              datasetDetails.context["5_sentences"]
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {isValuePresent(datasetDetails?.context?.["1_word"]) && (
                        <div className="col-md-4">
                          <p>
                            <strong>1 Word:</strong>{" "}
                            {renderValue(
                              "1_word",
                              datasetDetails.context["1_word"]
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(datasetDetails?.context?.document) && (
                        <div className="col-md-4">
                          <p>
                            <strong>Document:</strong>{" "}
                            {renderValue(
                              "document",
                              datasetDetails.context.document
                            )}
                          </p>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        backgroundColor: "#001529",
                        color: "white",
                        padding: "10px",
                      }}
                    >
                      Additional Fields
                    </Card.Title>
                    <div className="row">
                      {isValuePresent(
                        datasetDetails?.additional_fields?.categorical
                      ) && (
                        <div className="col-md-6">
                          <p>
                            <strong>Categorical:</strong>{" "}
                            {renderValue(
                              "categorical",
                              datasetDetails.additional_fields.categorical
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.additional_fields?.scalar
                      ) && (
                        <div className="col-md-6">
                          <p>
                            <strong>Scalar:</strong>{" "}
                            {renderValue(
                              "scalar",
                              datasetDetails.additional_fields.scalar
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.additional_fields?.license
                      ) && (
                        <div className="col-md-6">
                          <p>
                            <strong>License:</strong>{" "}
                            {renderValue(
                              "license",
                              datasetDetails.additional_fields.license
                            )}
                          </p>
                        </div>
                      )}
                      {isValuePresent(
                        datasetDetails?.additional_fields?.license_other
                      ) && (
                        <div className="col-md-6">
                          <p>
                            <strong>License Other:</strong>{" "}
                            {renderValue(
                              "license_other",
                              datasetDetails.additional_fields.license_other
                            )}
                          </p>
                        </div>
                      )}
                      {datasetDetails?.additional_fields &&
                        renderAdditionalFields(
                          datasetDetails.additional_fields
                        )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
}

export default Dataset;
