import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Pagination, Select, Spin, Table } from "antd";
import ISO6391 from "iso-639-1";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUBMIDatasetsSearchable, searchUBMI } from "../../store/search";
import DownloadButton from "../download-button/DownloadButton";
import AdditionalDetailsModal from "./AdditionalDetailsModal";
import BroaderContextModal from "./BroaderContextModal";
import TargetExpression from "./TargetExpression";

const SearchPage = () => {
  const { Option } = Select;

  const dispatch = useDispatch();
  const { loading, searchResults, datasetsSearchableList } = useSelector(
    (state) => state.search
  );

  const [datasetSearch, setDatasetSearch] = useState();
  const [languageSearch, setLanguageSearch] = useState();
  const [expressionSearch, setExpressionSearch] = useState("");
  const [labelSearch, setLabelSearch] = useState(null);
  // const [mscore, setMScore] = useState([0, 1]);
  const [contextSearch, setContextSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [inputValues, setInputValues] = useState({
    dataset: "",
    expression: "",
    label: "",
    context: "",
    mscore: [],
  });
  const [inputParams, setInputParams] = useState({});

  const languages = ISO6391.getAllNames();

  const labelOptions = [
    { value: "m", label: "Metaphor" },
    { value: "l", label: "Literal" },
    { value: "t", label: "Target" },
    { value: "a", label: "Anomalous" },
    { value: "u", label: "Uncertain" },
  ];

  const columns = [
    {
      title: "Dataset",
      dataIndex: "dataset",
      key: "dataset",
      render: (text) => <Link to={`/dataset/${text}`}>{text}</Link>,
    },
    { title: "Instance_ID", dataIndex: "instance_id", key: "instance_id" },
    { title: "Label", dataIndex: "label", key: "label" },
    {
      title: "Expression",
      dataIndex: "expression",
      key: "expression",
    },
    {
      title: "Target",
      dataIndex: "targetExpression",
      key: "targetExpression",
      render: (text, record) => <TargetExpression record={record} />,
    },
    {
      title: "Context",
      dataIndex: "context",
      key: "context",
      render: (text, record) => (
        <BroaderContextModal text={text} record={record} />
      ),
    },
    {
      title: "Additional Details",
      key: "additionalDetails",
      render: (_, record) => <AdditionalDetailsModal record={record} />,
    },
  ];

  const onDropDownChange = (selectedItems) => {
    setDatasetSearch(selectedItems);
  };

  const onLanguageChange = (selectedItems) => {
    setLanguageSearch(selectedItems);
  };

  const handleLabelChange = (selectedItem) => {
    setLabelSearch(selectedItem);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const handlePageSizeChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  // const handleSliderChange = (value) => {
  //   setMScore(value);
  // };

  const handleSubmit = (event) => {
    setInputValues({
      language: languageSearch,
      dataset: datasetSearch,
      expression: expressionSearch,
      label: labelSearch,
      context: contextSearch,
      // mscore: mscore,
    });
  };

  useEffect(() => {
    const searchRequest = {
      q: inputValues,
      page: currentPage,
      size: pageSize,
    };
    setInputParams({ ...searchRequest });
    dispatch(searchUBMI(searchRequest));
    dispatch(getUBMIDatasetsSearchable());
  }, [dispatch, inputValues, currentPage, pageSize]);

  const handleClear = () => {
    setInputValues({
      datasetSearch: null,
      expressionSearch: "",
      labelSearch: "",
      contextSearch: "",
    });
    setDatasetSearch(null);
    setLanguageSearch(null);
    setContextSearch("");
    setExpressionSearch("");
    setLabelSearch("");
    setCurrentPage(1);
    setPageSize(10);
    window.location.reload();
  };

  return (
    <div className="p-4">
      <h2
        style={{
          textAlign: "center",
          backgroundColor: "#001529",
          color: "white",
          padding: "10px",
        }}
      >
        Search MetaphorShare Data
      </h2>
      <div className="mb-3">
        {" "}
        {/* Bootstrap class for margin-bottom */}
        <div className="row">
          {" "}
          {/* Bootstrap row with gutters */}
          <div className="col">
            <Select
              showSearch
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Search by Dataset"
              optionFilterProp="children"
              defaultValue={datasetSearch}
              onChange={onDropDownChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.entries(datasetsSearchableList)?.map(([key, value]) => (
                <Option key={key} value={key}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col">
            <Select
              showSearch
              mode="multiple"
              allowClear
              style={{ width: "100%" }}
              placeholder="Search by Language"
              optionFilterProp="children"
              defaultValue={languageSearch}
              onChange={onLanguageChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {languages?.map((language, index) => (
                <Option key={index} value={language}>
                  {language}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col">
            <Select
              showSearch
              allowClear
              style={{ width: "100%" }}
              placeholder="Search by Label"
              optionFilterProp="children"
              defaultValue={labelSearch}
              onChange={handleLabelChange}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {labelOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="col-auto">
            {" "}
            {/* Bootstrap class for auto-width columns */}
            <Input
              placeholder="Search by Expression"
              value={expressionSearch}
              onChange={(e) => setExpressionSearch(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <Input
              placeholder="Search by Context"
              value={contextSearch}
              onChange={(e) => setContextSearch(e.target.value)}
            />
          </div>
        </div>
        {/* <div className="row mt-3">
          <div className="col-2">
            <Slider
              min={0}
              max={1}
              step={0.1}
              range={{
                draggableTrack: false,
                minCount: 0,
                maxCount: 1,
              }}
              onChange={handleSliderChange}
              defaultValue={[0, 1]}
            />
          </div>
        </div> */}
        <div className="row mt-3">
          <div className="col-auto">
            <Button
              type="primary"
              onClick={handleSubmit}
              icon={<SearchOutlined />}
            >
              Search
            </Button>
          </div>
          <div className="col-auto">
            <Button onClick={handleClear}>Clear</Button>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <DownloadButton
          downloadUrl="/es/download"
          inputParams={{ ...inputParams, size: 1000 }}
          disable={searchResults?.total_hits === 0}
        />
      </div>
      <Spin spinning={loading}>
        <Table
          dataSource={searchResults?.hits}
          columns={columns}
          pagination={false}
        />
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={searchResults?.total_hits}
          onChange={handlePageChange}
          onShowSizeChange={handlePageSizeChange}
          showSizeChanger
          showQuickJumper
          pageSizeOptions={["10", "20", "30", "50"]} // Optional: Customize the page size options
        />
      </Spin>
    </div>
  );
};

export default SearchPage;
