import { Modal, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile, validateFile } from "../store/upload";
import FileTable from "./table/FileTable";

const { Step } = Steps;

const ModalPage = ({ modalVisible, uploadData, onModalClose, initialValues, resetForm }) => {
  const dispatch = useDispatch();
  const { loading, validating } = useSelector(
    (state) => state.upload
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [validationResult, setValidationResult] = useState(null);

  useEffect(() => {
    if (modalVisible && uploadData) {
      // Execute code as soon as the modal pops up (e.g., additional initialization)
      // You can add your specific logic here
      dispatch(uploadFile(uploadData))
        .then((res) => {
          // const responseFileSave = res.payload.saved_files;
          setTimeout(() => {
            setCurrentStep(1);
            dispatch(validateFile(res.payload))
              .then((result) => {
                const results = result.payload;
                setTimeout(() => {
                  setCurrentStep(2);
                  setValidationResult(results?.file_stats);
                  if (results?.file_stats) {
                    results.file_stats.forEach((file) => {
                      if (file.file_status === "REJECTED") {
                        resetForm(false);
                        localStorage.setItem('formData', JSON.stringify(initialValues));
                      } else {
                        resetForm(true);
                        localStorage.removeItem('formData');
                      }
                    });
                  }
                }, 2000);
              })
              .catch((err) => alert("not success"));
          }, 2000);
        })
        .catch((err) => alert("not success"));
    }
  }, [modalVisible, dispatch, uploadData, initialValues, resetForm]);

  const handleModalClose = () => {
    onModalClose(resetForm);
    modalVisible = false;
    setCurrentStep(0);
    setValidationResult(null);
  };

  const handleModalOk = async () => {
    onModalClose(resetForm);
    modalVisible = false;
    setCurrentStep(0);
    setValidationResult(null);
  };

  return (
      <Modal
        title="File Upload and Validation"
        open={modalVisible}
        onOk={handleModalOk}
        onCancel={handleModalClose}
        footer={null}
        width='auto' // Set the width to auto to adjust based on content
        style={{ maxWidth: '60%' }}
      >
        <Steps current={currentStep} size="small">
          <Step title="Upload" />
          <Step title="Validation" />
          <Step title="Results" />
        </Steps>

        <div style={{ marginTop: "16px" }}>
          {currentStep === 0 && (
            <Spin spinning={loading} tip="Uploading...">
              <p>Uploading files...</p>
            </Spin>
          )}

          {currentStep === 1 && (
            <Spin spinning={validating} tip="Validating...">
              <p>Validating your files...</p>
            </Spin>
          )}

          {currentStep === 2 && validationResult && (
            <div>
              {validationResult.map((file, index) => (
                <div key={index}  style={{ border: '1px solid #e8e8e8', padding: '10px', marginBottom: '10px' }}>
                  <p>
                    <strong>File Name:</strong> <span style={{ color: file.file_status === 'REJECTED' ? 'red' : file.file_status === 'UNDER_REVIEW' ? 'green' : 'black', fontWeight: 'bold' }}>{file.file_name}</span>
                  </p>
                  <p>
                    <strong>Status:</strong> <span style={{ color: file.file_status === 'REJECTED' ? 'red' : file.file_status === 'UNDER_REVIEW' ? 'green' : 'black', fontWeight: 'bold' }}>{file.file_status}</span>
                  </p>
                  {file?.stats && (
                    <div>
                      <FileTable file={file} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </Modal>
  );
};

export default ModalPage;
