import http from "../services/httpService";

export const FILE_UPLOAD_URL = `/files/dataset/submit`;
export const FILE_VALIDATE_URL = `/files/dataset/validate`;
export const UBMI_SEARCH_URL = `/es/search`;
export const UBMI_GET_DATASET_LIST = `/es/dataset-names`;
export const UBMI_GET_DATASET_SEARCHABLE_LIST = `/es/dataset-names-searchable`;
export const GET_DATASET_DETAILS = `/es/dataset-details`;
export const DOWNLOAD_DATASET_FILE = `/files/download`;

export function uploadFile(formData, onUpload) {
  const config = {
    onUploadProgress: (progressEvent) => {
      const progressValue = Math.floor(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onUpload(progressValue);
    },
  };
  return http.post(FILE_UPLOAD_URL, formData, config);
}

export function valdiateFile(formData) {
  return http.post(FILE_VALIDATE_URL, formData);
}

export function searchUBMI(formData) {
  return http.post(UBMI_SEARCH_URL, formData);
}

export function getUBMIDatasetsSearchableList() {
  return http.get(UBMI_GET_DATASET_SEARCHABLE_LIST);
}

export function getUBMIDatasetsList() {
  return http.get(UBMI_GET_DATASET_LIST);
}

export function getDatasetDetails(formData) {
  return http.post(GET_DATASET_DETAILS, formData);
}

export function downloadDatasetFile(datasetName) {
  // Encode the dataset name to handle special characters safely
  const encodedName = encodeURIComponent(datasetName);

  return http.get(`${DOWNLOAD_DATASET_FILE}/${encodedName}`, {
    responseType: "blob",
  });
}


